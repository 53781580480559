/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Location } from 'react-router'
import { type lngShort, seoLanguageMap } from '../i18n.utils'

interface MetaProps {
	title?: string
	description?: string
	image?: string
	root?: boolean
	robots?: string
	structuredData?: any
	location?: Location
	canonical?: lngShort
}

export const SetMeta = ({
	title,
	description,
	image,
	structuredData,
	robots = 'all',
	root = false,
	location = undefined,
	canonical = 'sv',
}: MetaProps) => {
	const baseUrl = 'https://evently.se'
	let path = location?.pathname || ''
	if (path === '/') path = ''

	const lngPrefixes: lngShort[] = ['sv', 'en', 'no', 'da', 'fi']
	const pathParts = path.split('/')
	if (lngPrefixes.includes(pathParts[1] as lngShort)) {
		pathParts.splice(1, 1)
		path = pathParts.join('/')
	}
	const seoImage = image ? baseUrl + image : baseUrl + '/img/evently-start.png'

	const formattedTitle = title ? title : 'Error'
	const formattedDescription = description
		? formatDescription(description, 200)
		: ''

	return [
		{ title: formattedTitle + (!root ? ' | Evently' : '') },
		{
			name: 'description',
			property: 'og:description',
			content: formattedDescription,
		},
		{ name: 'application-name', content: 'Evently' },

		{ name: 'og:title', content: formattedTitle },
		{ name: 'og:image', content: seoImage },
		{ name: 'og:type', content: 'website' },
		{ name: 'og:site_name', content: 'Evently' },

		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:title', content: formattedTitle },
		{ name: 'twitter:description', content: formattedDescription },
		{ name: 'twitter:image', content: seoImage },
		{ name: 'twitter:domain', content: 'evently.se' },
		{ name: 'robots', content: robots },

		...Object.entries(seoLanguageMap).map(([_, lang]) => {
			return {
				rel: lang === canonical ? 'canonical' : 'alternate',
				tagName: 'link',
				hrefLang: lang,
				href: `${baseUrl}${lang === 'sv' ? '' : `/${lang}`}${decodeURIComponent(path)}`,
			}
		}),

		...(structuredData ? [structuredData] : []),
	]
}

export function formatDescription(description: string, maxLength: number) {
	if (
		!description ||
		description ===
			'<p class="text-body-md mb-2">För mer information, besök evenemangets hemsida.</p>' ||
		description === '<p class="text-body-md mb-2"></p>'
	) {
		return ''
	}
	const desc = description
		.replace(/(<([^>]+)>)/gi, '')
		.replace(/&nbsp;/g, ' ')
		.replace(/\n/g, ' ')
	if (desc.length > maxLength) {
		// Find the last character index within maxLength
		let lastIndex = maxLength
		while (
			lastIndex >= 0 &&
			(desc.charAt(lastIndex) !== ' ' || desc.charCodeAt(lastIndex) > 255)
		) {
			lastIndex--
		}
		// Check if the last character is a space
		if (lastIndex > 0 && desc.charAt(lastIndex) === ' ') {
			return desc.substring(0, lastIndex) + '...'
		} else {
			return desc.substring(0, maxLength) + '...'
		}
	}
	return desc
}
